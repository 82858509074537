#name {
    width: 100%;
    text-align: center;
    color: white;
    font-size: 20px;
}

#submit {
    background-color: green;
    color: white;
    outline: none;
    font-size: 20px;
    border-radius: 30px;
    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;
    border: 2px solid green;
    display: block;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

#load {
    margin: auto;
    margin-top: 10%;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #232525;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 3s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}