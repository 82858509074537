#pangrams {
    background-color: white;
    color: black;
    margin-left: 30%;
    width: 40%;
    text-align: justify;
    padding: 10px 10px;
    outline: none;
    border-radius: 10px;
    margin-top: 3%;
    margin-bottom: 3%;
}

#title {
    color: white; 
    font-size: 60px;
    margin-bottom: 50px;
}