
#textfield {
    background-color: white;
    color: black;
    margin-left: 30%;
    width: 40%;
    text-align: justify;
    padding: 10px 10px;
    outline: none;
    border-radius: 10px;
    animation: animation 1.5s linear;
}