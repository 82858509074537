.transition {
    opacity: 0;
    animation: anim 3s linear;
}

@keyframes anim {
    0%  {opacity:0;}
    50% {opacity:1;}
    100% {opacity:0;}
}

