.App {
    font-family: Helvetica, Arial, Verdana;
    display: flex;
}

#menu {
    bottom: 15px;
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

#menu button {
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    font-size: 20px;
    padding-right: 50px;
}
