main{
    color: white;
    /* display: flex; */
    width: 100%;
    text-align: center;
    margin: auto;
    align-content: center;
}

ul{
    list-style: none;
}

a{
    color: white;
    text-decoration: none;
}

a:hover{
    color: black;
}